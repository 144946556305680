const visibleSections = document.getElementById("visibleSections");
const hiddenSections = document.getElementById("hiddenSections");
const masthead = document.getElementById("navbar");
const page = document.getElementById("page");

document.addEventListener("alpine:init", () => {
  Alpine.data("data", () => ({
    menu: false,
    lastScrollTopPosition: 0,
    buttons: [],
    showButtons: false,
    showAll: false,
    oneMore: false,
    init() {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        if (section.dataset.label === undefined) {
          return;
        }
        this.buttons.push({
          id: section.id,
          label: section.dataset.label,
          menu: section.dataset.menu,
          hideButton: false,
        });
      });

      this.showButtons = true;
    },
    showSection(id) {
      //hide menu if open
      if (this.menu) {
        this.toggleMenu();
      }

      //hide buttons while we move down
      // this.showButtons = false;

      const section = document.getElementById(id);

      //check if section is already in visibleSections
      if (visibleSections.contains(section)) {
        window.scrollTo({
          top: section.offsetTop - masthead.offsetHeight + 2,
          behavior: "smooth",
        });
      } else {
        //get height of section contents including padding and border
        //let sectionHeight = section.offsetHeight;
        let sectionHeight = section.getBoundingClientRect();

        section.style.maxHeight = sectionHeight + "px";

        section.classList.add("prefade");

        visibleSections.appendChild(section);

        setTimeout(() => {
          section.classList.remove("prefade");
        }, 50);
        setTimeout(() => {
          autoScroll(section);
        }, 300);
      }

      //set hideButton to true
      this.buttons = this.buttons.map((button) => {
        if (button.id === id) {
          button.hideButton = true;
        }
        return button;
      });

      // check if there is only one section left

      if (hiddenSections.children.length === 1) {
        this.oneMore = true;
      }

      if (hiddenSections.children.length === 0) {
        this.showAll = true;
      }

      //show buttons again
      //this.showButtons = true;
    },
    showAllSections() {
      //get id of first child in hiddenSections
      let id = hiddenSections.firstElementChild.id;
      //move contents of #hiddenSections into #visibleSections
      while (hiddenSections.children.length > 0) {
        visibleSections.appendChild(hiddenSections.firstElementChild);
      }

      this.showAll = true;
      this.showSection(id);
    },
    toggleMenu() {
      if (!this.menu) {
        this.lastScrollTopPosition = window.scrollY;
        document.body.style.height = "100vh";
        document.body.style.overflowY = "hidden";
      }

      this.menu = !this.menu;

      if (!this.menu) {
        document.body.style.height = "auto";
        document.body.style.overflowY = "visible";
        window.scrollTo(0, this.lastScrollTopPosition);
      }

      var menuItems = document.querySelectorAll("#nav-main li");

      if (this.menu) {
        menuItems.forEach((item, index) => {
          setTimeout(function () {
            item.classList.remove("opacity-0");
            item.classList.remove("translate-x-10");
          }, 50 * (index + 1));
        });
      } else {
        menuItems.forEach((item) => {
          item.classList.add("opacity-0");
          item.classList.add("translate-x-10");
        });
      }
    },
  }));
});

function autoScroll(section) {
  //check if if section h2 is below #masthead in the viewport
  if (section.offsetTop > window.scrollY + masthead.offsetHeight) {
    //scroll to top of section offset by masthead height
    window.scrollTo({
      top: section.offsetTop - masthead.offsetHeight + 2,
      behavior: "smooth",
    });
  } else {
    //scroll to bottom of page
    window.scrollTo({ top: page.scrollHeight, behavior: "smooth" });
  }
}

new SimpleBar(document.getElementById("logos"), {
  scrollbarMaxSize: 400,
  scrollbarMinSize: 100,
  autoHide: false,
});
